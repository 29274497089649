/* eslint-disable @next/next/no-img-element */
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { EyeIcon, EyeOffIcon, AtSymbolIcon } from '@heroicons/react/solid';
import { useAuth } from 'utils/context/auth';
import { useRouter } from 'next/router';
import { Button, RequiredAsterisk } from '@utils/ui';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { titleMessageResponse } from 'global/utils/message.response.utils';
import { CONFIG_SITE, USER_ROLE } from 'constants/index';
import Link from 'next/link';

const LoginContainer = () => {
  const isIdFood = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.idfood;

  const router = useRouter();

  const { login, user, isLoading } = useAuth();
  const { t } = useTranslation('login-page');
  const [isShowPassword, setIsShowPassword] = useState(false);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const loginMutation = useMutation((data) => login(data), {
    onError: (error) => {
      toast.error(error.data.message || 'Oops, terjadi kesalahan');
    },
  });

  useEffect(() => {
    if (!isLoading) {
      if (user.auth.isLogedIn) {
        router.replace('/');
      }
    }
  }, [isLoading, router, user.auth.isLogedIn]);

  const onSubmit = async (data) => loginMutation.mutateAsync(data);

  const renderEyeIcons = () => {
    if (!isShowPassword) {
      return <EyeOffIcon className="w-[20px] h-[20px] text-[#bbb]" />;
    }
    return <EyeIcon className="w-[20px] h-[20px] text-[#bbb]" />;
  };

  return (
    <>
      <div className="h-auto flex flex-row p-4 md:p-0 md:h-screen">
        <div className="relative w-full md:bg-grayScale02">
          <div className="absolute w-[150px] h-[52px] text-center left-[50%] top-[30px] translate-x-[-50%] lg:left-[50px] lg:top-[50px] lg:translate-x-[0%]">
              <Image src="/xooply-logo.png" layout="fill" alt="Logo" width={150} height={52}/>
          </div>
          <div className="flex md:items-center pt-[90px] lg:pt-[0px] justify-center lg:h-screen">
            <div className='text-center mt-[10px] p-[25px] rounded-xl bg-[#fff] md:w-[495px] shadow-md'>
              <h1 className="font-semibold text-lg xl:text-2xl text-grayScale05 mb-[10px] flex items-center gap-4 justify-center">
                Selamat datang di
                <span className="relative w-[109px] h-[38px]">
                  <Image src="/xooply-logo.png" layout="fill" alt="Logo" />
                </span>
              </h1>
              <p className="text-sm text-[#555] font-normal leading-[150%]">
              Masuk ke akun Anda untuk menikmati <br/>
              pengalaman belanja yang lebih nyaman.
              </p>
              <form className="mt-[30px] text-left" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-[20px] font-semibold text-sm relative">
                  <span className="block">
                    <span className="text-grayScale05 font-semibold text-sm">{t('Email')}</span>
                  </span>
                  <input
                    type="text"
                    {...register('username', {
                      required: true,
                    })}
                    className="mt-[4px] px-[21px] py-[10px] bg-white border shadow-sm border-gray-300 placeholder-[#bbbbbb] text-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Masukkan email Anda"
                  />
                  <span className='absolute right-[0px] top-[28px] p-[10px] z-10'>
                    <AtSymbolIcon className="w-[20px] h-[20px]  text-[#bbbbbb]" />
                  </span>
                  <span className="mt-[4px] text-red-500 text-xs font-light">
                    {errors.username?.type === 'required' && 'Email wajib diisi'}
                  </span>
                </div>
                <div className="relative">
                  <span className="block">
                    <span className="text-grayScale05 font-semibold text-sm">Kata Sandi</span>
                  </span>
                  <input
                    maxLength={95}
                    type={isShowPassword ? 'text' : 'password'}
                    {...register('password', {
                      required: true,
                    })}
                    className="mt-[4px] px-[21px] py-[10px] bg-white border shadow-sm border-gray-300 placeholder-[#bbbbbb] text-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="Masukkan kata sandi Anda"
                  />
                  <button
                    type="button"
                    className="cursor-pointer absolute right-[0px] top-[28px] p-[10px] z-10"
                    onClick={() => {
                      setIsShowPassword(!isShowPassword);
                    }}
                  >
                    {renderEyeIcons()}
                  </button>
                  <span className="mt-[4px] text-red-500 text-xs font-light">
                    {errors.password?.type === 'required' && 'Kata Sandi wajib diisi'}
                  </span>
                </div>
                <a
                  href="/forgot-password"
                  className="text-xs capitalize font-semibold block mt-[10px] text-theme-primary-main hover:text-theme-primary-main bg-clip-text bg-gradient-to-br from-primary to-theme-primary-lighter"
                >
                  Lupa Kata Sandi?
                </a>
                <Button
                  htmlType="submit"
                  block
                  variant="primary"
                  className="mt-[24px] mb-[24px]"
                  size="large"
                  loading={loginMutation.isLoading && !loginMutation.isError}
                >
                  Masuk
                </Button>

                <div className="text-center">
                  <span className="text-sm text-center font-light">Belum Memiliki Akun? </span>
                  <a
                    href={'/register'}
                    className="text-sm text-theme-primary-main font-semibold cursor-pointer gtm-register-anchor"
                  >
                    Daftar
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className='lg:absolute lg:bottom-[30px] lg:left-[50%] lg:translate-x-[-50%] mt-[20px] lg:mt-[0px]'>
            <div className="text-[#666] text-sm text-center">
              © 2025 XooplyID. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export async function getStaticProps() {
  return {
    props: {},
  };
}

export default LoginContainer;
